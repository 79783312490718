'use client';

import {
  FormControl, InputLabel, MenuItem, PaletteMode, Select, SelectChangeEvent, TextField,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { MouseParallax } from 'react-just-parallax';
import client from '../../apiClient';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import { theme } from '../../store/theme';
import useDebouncedCallback from '../../useDebounceCallback';
import AnimatedTitle from '../AnimatedTitle';
import Button, { ButtonVariants } from '../Button';
import styles from './Form.module.scss';
import Ellipse, { EllipseVariants } from '../Ellipse';
import {
  FormComponentData, FormComponentItem, FormComponentSelect, FormSubmittedData,
} from './interfaces';

const AngleIcon = () => (
  <svg className={styles.dropdownIcon}>
    <use
      xlinkHref='/media/angle_down.svg#angleDown'
      href='/media/angle_down.svg#angleDown'
    />
  </svg>
);

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'dark'
      ? {
        primary: {
          main: '#6BD8C7', // --primary-500
        },
      }
      : {
        primary: {
          main: '#3CBFA6', // --primary-900
        },
      }),
  },
});

type Errors = {
  [key: string]: string | undefined,
};

enum SubmitStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

const Form = ({
  className, modalView = true, data, onSuccessSubmit,
}: {
  className?: string,
  modalView?: boolean,
  data: FormComponentData,
  onSuccessSubmit: () => void
}) => {
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const muiTheme = useMemo(() => createTheme(getDesignTokens(appTheme)), [appTheme]);

  const setIsModalOpened = data?.openedModal && useSetAtom(data.openedModal);

  const [isFormMutated, setIsFormMutated] = useState<boolean>(false);

  const requiredFields = data.fields.filter((field: FormComponentItem) => !!field?.errors?.required);
  const isFormFilled = requiredFields.every(field => field.value.length > 0);
  const [errors, setErrors] = useState<Errors>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);
  const [customServerError, setCustomServerError] = useState<string | null>(null);

  const validateForm = () => {
    setErrors({});
    let currentErrors: Errors = {};

    requiredFields.forEach(field => {
      if (field.value === '') {
        currentErrors[field.name] = field.errors?.required;
      }
      if (field.errors?.custom) {
        field.errors.custom.forEach(customError => {
          if (customError.condition) {
            currentErrors[field.name] = customError.value;
          }
        });
      }
    });
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const validateMutatedForm = useDebouncedCallback(() => {
    validateForm();
  }, 300);

  useEffect(() => {
    if (isFormMutated) {
      validateMutatedForm();
    }
  }, [data.fields, isFormMutated]);

  const submitForm = async (formData: FormSubmittedData) => {
    setIsLoading(true);
    setSubmitStatus(null);
    setCustomServerError(null);
    try {
      await client.post(data.submitData.endpoint, { cache: 'no-cache', body: JSON.stringify(formData) });
      setSubmitStatus(SubmitStatus.SUCCESS);
      setIsFormMutated(false);
      onSuccessSubmit();
      setIsModalOpened && setTimeout(() => {
        setIsModalOpened(null);
      }, 2000);
    } catch (e) {
      console.error(e.message);
      setSubmitStatus(SubmitStatus.ERROR);
      if (e.response.status === 429) {
        setCustomServerError('Too many attempts, please try again in a minute.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsFormMutated(true);
    const isFormValid = validateForm();

    isFormValid && submitForm(data.submitData.value);
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classnames(styles.contact, className, {
        [styles.contact_modal]: modalView,
      })}
      >
        <div className={styles.ellipses}>
          <MouseParallax
            shouldPause={false}
            isAbsolutelyPositioned
            strength={0.04}
          >
            <Ellipse
              variant={EllipseVariants.DRAW}
              size={deviceSize === ScreenSize.MOBILE ? 490 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 770 : 823}
              className={classnames(styles.ellipse, styles.ellipse_draw)}
            />
          </MouseParallax>
          <MouseParallax
            shouldPause={false}
            isAbsolutelyPositioned
            strength={0.17}
          >
            <Ellipse
              variant={EllipseVariants.BORDER}
              size={deviceSize === ScreenSize.MOBILE ? 20 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 31 : 33}
              className={classnames(styles.ellipse, styles.ellipse_border)}
            />
          </MouseParallax>
          <MouseParallax
            shouldPause={false}
            isAbsolutelyPositioned
            strength={0.08}
          >
            <Ellipse
              variant={EllipseVariants.NOISE}
              className={classnames(styles.ellipse, styles.ellipse_noise)}
              size={deviceSize === ScreenSize.MOBILE ? 137 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 215 : 230}
            />
          </MouseParallax>
          <MouseParallax
            shouldPause={false}
            isAbsolutelyPositioned
            strength={0.14}
          >
            <Ellipse
              variant={EllipseVariants.NOISE}
              className={classnames(styles.ellipse, styles.ellipse_noise2)}
              size={deviceSize === ScreenSize.MOBILE ? 77 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 120 : 129}
            />
          </MouseParallax>
          <MouseParallax
            shouldPause={false}
            isAbsolutelyPositioned
            strength={0.22}
          >
            <Ellipse
              variant={EllipseVariants.GRADIENT}
              className={classnames(styles.ellipse, styles.ellipse_gradient)}
              size={deviceSize === ScreenSize.MOBILE ? 30 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 48 : 51}
            />
          </MouseParallax>
          <MouseParallax
            shouldPause={false}
            isAbsolutelyPositioned
            strength={0.22}
          >
            <Ellipse
              variant={EllipseVariants.GRADIENT}
              className={classnames(styles.ellipse, styles.ellipse_gradient2)}
              size={deviceSize === ScreenSize.MOBILE ? 60 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 95 : 101}
            />
          </MouseParallax>
        </div>

        <div className={classnames(styles.contact__content, 'container')}>
          <div className={styles.info}>
            <h2 className={styles.title}><AnimatedTitle title={data.title} /></h2>
            <p className={styles.description}>{data.description}</p>
          </div>
          <form
            className={styles.form}
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {
              data.fields.map(field => {
                if (field.type === 'select') {
                  return (
                    <FormControl
                      key={field.id}
                      size='small'
                      variant='standard'
                      sx={{ m: 1, minWidth: 120 }}
                      className={styles.select}
                    >
                      <InputLabel id={`${field.id}Label`}>{field.label}</InputLabel>
                      <Select
                        labelId={`${field.id}Label`}
                        id={field.id}
                        value={field.value}
                        onChange={(event: SelectChangeEvent) => field.setValue((event.target.value))}
                        label={field.label}
                        MenuProps={{
                          classes: {
                            paper: styles.selectDropdown,
                            list: styles.selectList,
                          },
                        }}
                        IconComponent={AngleIcon}
                      >
                        {(field as FormComponentSelect).list.map(item => (
                          <MenuItem
                            value={item}
                            key={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                } else {
                  return (
                    <TextField
                      key={field.id}
                      value={field.value}
                      onChange={e => field.setValue(e.target.value)}
                      size='small'
                      error={!!errors[field.name]}
                      helperText={!!errors[field.name] && errors[field.name]}
                      className={classnames(styles.input, {
                        [styles.input_half]: field.width === 'half',
                      })}
                      id={field.id}
                      label={field.label}
                      type={field.inputType}
                      variant='standard'
                      required={!!field.errors?.required || false}
                      multiline={field.type === 'textarea'}
                      minRows={field.type === 'textarea' ? 4 : undefined}
                    />
                  );
                }
              })
            }
            <footer className={classnames(styles.footer)}>
              {submitStatus && (
                <p className={classnames(styles.status, {
                  [styles.error]: submitStatus === SubmitStatus.ERROR,
                  [styles.success]: submitStatus === SubmitStatus.SUCCESS,
                })}
                >
                  {submitStatus === SubmitStatus.ERROR && (customServerError || 'Form submission error. Try again')}
                  {submitStatus === SubmitStatus.SUCCESS && 'The form has been successfully submitted'}
                </p>
              )}
              <Button
                type='submit'
                variant={ButtonVariants.OUTLINED}
                disabled={!isFormFilled || (isFormMutated && Object.keys(errors).length > 0)}
                className={styles.sendButton}
                icon={isLoading && (
                  <img
                    src={`/media/spinner_${appTheme}.webp`}
                    alt='spinner'
                  />
                )}
                iconSize={{ width: 16, height: 16 }}
                size={deviceSize > ScreenSize.MOBILE ? 'medium' : 'normal'}
              >
                {data.sendButtonText || 'Send'}
              </Button>
            </footer>
          </form>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Form;
